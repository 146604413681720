import Vue from 'vue'
import { PiniaVuePlugin } from 'pinia'

import App from '@/App/App.vue'

import plugins from '@/plugins'

import { router } from '@/router/router'

import store from '@/store'
import { pinia } from './store/pinia'

import '@/styles/main.scss'

const APP_VERSION = import.meta.env.APP_VERSION

// Vue setup
Vue.config.productionTip = false
Vue.config.devtools = import.meta.env.MODE !== 'production'
Vue.config.performance = import.meta.env.MODE !== 'production'

Vue.use(PiniaVuePlugin)

const vue = new Vue({
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  components: { App },
  data() {
    return { environment: import.meta.env.MODE, version: APP_VERSION }
  },
  router,
  store,
  ...plugins,
  template: '<App/>',
  pinia,
})

vue.$mount('#app')
