<template>
  <div :id="appId" class="h-full">
    <router-view />
    <ToastManager />
    <CrescendoBot />
  </div>
</template>
<script>
import { useFavicon } from '@vueuse/core'

import { FeatureFlags } from '@/plugins/featureFlags'
import globalStore from '@/store'

import CrescendoBot from './components/CrescendoBot.vue'
import ToastManager from './components/toasts/ToastManager'
import localeHandler from './mixins/localeHandler'
import pageTitle from './mixins/pageTitle'
import sessionManager from './mixins/sessionManager'

const VITE_APP_ID = import.meta.env.VITE_APP_ID

export default {
  name: 'App',
  components: {
    CrescendoBot,
    ToastManager,
  },
  mixins: [localeHandler, pageTitle, sessionManager],
  data() {
    return {
      appId: VITE_APP_ID,
    }
  },
  created() {
    this.updateFeatureFlagCtx()
  },
  methods: {
    updateFeatureFlagCtx() {
      if (globalStore.getters['session/user']) {
        FeatureFlags.setContextField(
          'userId',
          globalStore.getters['session/user'].email
        )
      }
    },
  },
  setup() {
    useFavicon(
      import.meta.env.MODE !== 'production'
        ? '/favicon/favicon-dev.ico'
        : '/favicon/favicon.ico'
    )
  },
}
</script>
