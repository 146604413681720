import { MotionPlugin } from '@vueuse/motion'
import Vue from 'vue'

import AnalyticsPlugin from './analytics/plugin'
import FeatureFlagsPlugin from './featureFlags'
import i18n from './i18n'
import './sentry'

Vue.use(AnalyticsPlugin)
Vue.use(FeatureFlagsPlugin)

Vue.use(MotionPlugin)

export default {
  i18n,
}
